import React from "react";
import styles from "./styles.module.scss";

const ContactInfo = () => {
  return (
    <div className={styles.contactInfo}>
      <ul className={styles.contactDetails}>
        <li>
          Email: <a href="mailto:info@sudarshanservices.com"> info@sudarshanservices.com</a>
        </li>
        <li>
          Phone: <a href="tel:+919327056669">+91 93270 56669</a>
        </li>
      </ul>
      <ul className={styles.socialMedia}>
        <li>
          <a
            href="https://www.linkedin.com/company/sudarshanservices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-linkedin"></i>
            
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ContactInfo;
