import {  Route, Routes } from "react-router";
import styles from "./App.module.scss";
import PublicLayout from "./layout/PublicLayout";

const App = () => {
  return (
    <div className={styles.app}>
      <Routes>
        <Route path="/*" element={<PublicLayout />} />
        
      </Routes>
    </div>
  );
};

export default App;
