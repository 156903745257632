import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-scroll";
import logo from "../../../assets/image/logo.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.logo}>
            <img src={logo} alt="logo not found" />
          </div>
         
        </div>
        <div className={styles.links}>
          <h3 className={styles.sectionTitle}>Our Company</h3>
          <ul className={styles.linkList}>
            <li>
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="aboutUs"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="productList"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Product & Services
              </Link>
            </li>
          </ul>
        </div>
        <div className={styles.contact}>
          <h3 className={styles.sectionTitle}>Contact Information</h3>
          <p>
            Email: <a href="mailto:info@sudarshanservices.com">info@sudarshanservices.com</a>
          </p>
          <p>
            Phone: <a href="tel:+919327056669">+91 93270 56669</a>
          </p>
          <h3>FOLLOW US</h3>
          <div className={styles.socialMedia}>
            <a
            href="https://www.linkedin.com/company/sudarshanservices/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-linkedin"></i>
            
          </a>
          </div>
        </div>
        <div className={styles.address}>
          <h3 className={styles.sectionTitle}>Our Address</h3>
          <p>Address: Office - 207, Kansas complex, Ghodasar Cross Road, Ghodasar, Ahmedabad - 380050
<br/><br/>
Regd. Office - B-101, Shyamsrusti Appt., Kudasan Road, Gandhinagar - 382421</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <p>Copyright &copy; {currentYear} Sudarshan. All Rights Reserved.</p>
        <Link to="#" className={styles.terms}>
          Terms & Conditions
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
