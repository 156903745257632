import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import image1 from "../../assets/image/frozen-fish.jpg";
import image2 from "../../assets/image/Grain Agro Products.jpg";
import image3 from "../../assets/image/Chemical trading.jpg";
import image4 from "../../assets/image/Global_Business_Logistics_Import_Export_Background.jpeg.jpg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "./styles.scss";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";

const Carousel = () => {
  return (
    <>
      <Swiper
        spaceBetween={50}
        effect={"fade"}
        navigation={true}
        loop={true}
        autoplay={{
          delay: 3000, // Delay between slides in ms (3 seconds)
          disableOnInteraction: false, // Continue autoplay even after user interaction
        }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={image1} alt="image1" />
          <div className="subtitle" data-swiper-parallax="-200">
            Frozen Foods and Products
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={image2} alt="image2" />
          <div className="subtitle" data-swiper-parallax="-200">
            Agro Foods and Products
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={image3} alt="image3" />
          <div className="subtitle" data-swiper-parallax="-200">
            Chemical Trading
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src={image4} alt="image4" />
          <div className="subtitle" data-swiper-parallax="-200">
            Supply Chain Management
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Carousel;
