import { Navigate, Route, Routes } from "react-router";
import styles from "./styles.module.scss";
import { publicRoutes } from "../../routes";
import { getRoutes } from "../../utility/utils";
import Header from "./Header";
import ContactInfo from "./ContactHeader";

const PublicLayout = () => {

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <div className={styles.content} id="scrollView">  
          <ContactInfo />
          <Header />
          <Routes>
            {getRoutes(publicRoutes)}
            <Route path="*" element={<Navigate to={`/`} replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
