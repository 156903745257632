import React from "react";
import styles from "./styles.module.scss";
import Carousel from "../../components/Carousel";
import AboutUs from "../AboutUs";
import ProductList from "../Product&Services";
// import { Link } from "react-scroll";
import Footer from "../../layout/PublicLayout/Footer";

const Home = () => {
  return (
    <div>
      <div className={styles.home}>
        <div id="home" className={styles.homeContainer}>
          <Carousel />
        </div>
        <section id="productService">
          <div className={styles.productServiceContainer}>
            <p className={styles.productContent}>
              <p className={styles.productContent}>
              Welcome to Sudarshan Services, where innovation meets excellence. We specialize in delivering robust solutions tailored to meet your unique needs. Our team is dedicated to helping you achieve your goals with services designed to enhance efficiency, drive growth, and ensure long-term, sustainable success.  <br />
                <br />
                With a commitment to quality and customer satisfaction, we are your trusted partner in navigating today’s dynamic business landscape. Let’s shape the future together.
              </p>
            </p>
          </div>
        </section>
        <section id="aboutUs">
          <AboutUs />
        </section>
        <section id="productList">
          <ProductList />
        </section>
        <section id="contactUs">
          <Footer />
        </section>
      </div>
    </div>
  );
};

export default Home;
