import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../../../assets/image/logo.png";
import styles from "./styles.module.scss";
function NavBar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);

  const toggleDropdown = () => setDropdown(!dropdown);

  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.navContainer}>
          <div className={styles.navLogo}>
            <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={styles.navLinks}
                onClick={handleClick}
              >
                <img src={logo} alt="logo not found" />
              </Link>
          </div>

          <ul
            className={
              click ? `${styles.navMenu} ${styles.active}` : styles.navMenu
            }
          >
            <li className={styles.navItem}>
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={styles.navLinks}
                onClick={handleClick}
              >
                Home
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link
                to="aboutUs"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={styles.navLinks}
                onClick={handleClick}
              >
                About
              </Link>
            </li>
            <li
              className={styles.navItem}
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
            >
              <Link
                to="productList"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={styles.navLinks}
                onClick={handleClick}
              >
                Product & Services
              </Link>
              {dropdown && (
                <ul className={styles.dropdownMenu}>
                </ul>
              )}
            </li>
            <li className={styles.navItem}>
              <Link
                to="contactUs"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={styles.navLinks}
                onClick={handleClick}
              >
                Contact Us
              </Link>
            </li>
          </ul>

          <div className={styles.navIcon} onClick={handleClick}>
            {!click ? (
              <span className={styles.icon}>
                <i className="fa-solid fa-bars"></i>
              </span>
            ) : (
              <span className={styles.icon}>
                <i className="fa-solid fa-xmark"></i>
              </span>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
