import Home from "./pages/Home";

export const publicRoutes = [
  {
    path: "/",
    name: "home",
    component: Home,
    isPrivate: false,
    layout: "public",
  },
];
