import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./styles.scss";

import products from './product.json';

const ProductList = () => {
  return (
    <div className="product-accordion">
      <div className="heading">
        <h1>Product & Services</h1>
      </div>
      <Accordion allowZeroExpanded allowMultipleExpanded>
        {products.map((product, index) => (
          
          <AccordionItem key={index}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h2 className="accordionTitle">{product.title}</h2>

                <i className="fa-solid fa-chevron-down accordionIcon"></i>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>{product.description}</p>
              {product.routes && <p>{product.routes}</p>}
              
                  {product.clientele && product.clientele.length > 0 && product.clientele.map((p, idx) => (
                    <li key={idx}>{p}{idx !== product.clientele.length - 1 && ', '}</li>
                  ))}
                
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default ProductList;
