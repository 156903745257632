import React from "react";
import styles from "./styles.module.scss"; // Import the SCSS module
import image1 from "../../assets/image/img1.jpg";
import image2 from "../../assets/image/img2.jpg";
import image3 from "../../assets/image/img3.jpg";

const AboutUs = () => {
  return (
    <div className={styles.aboutUsContainer}>
      <div className={styles.heading}>
        <h1>About Us</h1>
      </div>

      <div className={styles.companyImage}>

        <section className={styles.missionSection}>
          <h2 className={styles.subtitle}>Who we were</h2>
          <p className={styles.description}>
            With over 20 years of expertise across various fields, Sudarshan Services was founded in 2015 as the crystallization of this vast experience into a new vision. From pioneering the Delhi-Kandla corridor in Supply Chain Management to leveraging international relations to meet national demands for chemical products, we have consistently been at the forefront of sustainable modernization. Our legacy is built on innovation, adaptability, and a commitment to meeting the evolving needs of the industries we serve.
          </p>
        </section>

        <section className={styles.aboutSection}>
          <h1 className={styles.subtitle}>Who we are</h1>
          <p className={styles.description}>
            Today, Sudarshan Services is a trusted partner to both multinational and national corporations, consistently setting new benchmarks in our field. We are dedicated to providing customized, reliable solutions tailored to the specific needs of our clients. Guided by a belief in long-term, mutually beneficial relationships, and driven by discipline, we tackle seemingly insurmountable challenges with ease. Our track record is a testament to our unwavering focus on delivering excellence and creating lasting value.
          </p>
        </section>

        <section className={styles.visionSection}>
          <h2 className={styles.subtitle}>Who we will be</h2>
          <p className={styles.description}>
            At Sudarshan Services, we believe in the transformative power of innovation and collaboration to drive industry-wide change. As an emerging company with deep-rooted expertise, we continue to seek new solutions to age-old challenges. Looking ahead, we aim to remain a steadfast and valuable partner to all who envision a prosperous future. Our commitment to growth, sustainability, and excellence ensures that we will be a key player in shaping tomorrow’s industries.
          </p>
        </section>

        <section className={styles.imageSection}>
        <img src={image3} alt="image3" />
          <img src={image1} alt="image1" />
          <img src={image2} alt="image2" />
          
        </section>

      </div>
    </div>
  );
};

export default AboutUs;
